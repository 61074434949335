import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import ThemeToggler from "./ThemeToggler"
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { BiServer, BiBook, BiPhone } from "react-icons/bi"
import { MdSettingsApplications, MdForum, MdNewReleases } from "react-icons/md"
import { CgMediaPodcast, CgWorkAlt } from "react-icons/cg"
import { SiMicroDotBlog } from "react-icons/si"
import { BsInfoCircle } from "react-icons/bs"

const products = [
    {
      name: "Antsle Appliances",
      description: 'Special offers for early birds',
      href: "/antsle-appliances",
      icon: BiServer
    },
    {
      name: "antMan",
      description: 'Your Server + antMan = Your Private Cloud',
      href: "/products/antmanedgelinux",
      icon: MdSettingsApplications
    },
  ]
  const resources = [
    {
      name: "Docs",
      description: 'Documentation for the Antsle Private Cloud',
      href: "https://docs.antsle.com/",
      icon: BiBook
    },
    {
      name: "FreedomCasts",
      description: 'Screencasts that show you how to use Antsle',
      href: "/freedomcasts",
      icon: CgMediaPodcast
    },
    {
      name: "Community Forum",
      description: 'Welcome to our Antsle community!',
      href: "/forum",
      icon: MdForum
    },
    {
      name: "Blog",
      description: 'Welcome to our blog',
      href: "/blog",
      icon: SiMicroDotBlog
    },
    {
      name: "Release Announcements",
      description: 'antMan and edgeLinux updates',
      href: "/company/release-announcements",
      icon: MdNewReleases
    },
  ]
  
  const company = [
    { name: "About", description: 'Our Army of Ants', href: "/company/about", icon: BsInfoCircle },
    { name: "Careers", description: 'Career opportunities with team Antsle', href: "/company/careers", icon: CgWorkAlt },
    { name: "Contact Us", description: 'We look forward to assisting you', href: "/company/contact", icon: BiPhone },
  ]

const callsToAction = [
  { name: 'Watch Demo', href: '/demo', icon: PlayIcon },
  { name: 'Contact Sales', href: '/company/contact', icon: PhoneIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  return (
    <Popover className="relative bg-white dark:bg-black z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 dark:border-gray-900 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Antsle</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="https://antsle.com/wp-content/uploads/2019/08/Antsle_logo_l.png"
                alt="Antsle Logo"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <div className="rounded-md p-2 mr-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-900">
              <span className="sr-only">Toggle Theme</span>
              <ThemeToggler className="h-6 w-6" aria-hidden="true" />
            </div>
            <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900 dark:text-gray-400' : 'text-gray-500 dark:text-gray-200',
                      'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900'
                    )}
                  >
                    <span>Products</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white dark:bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8">
                          {products.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-pink-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-200">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 dark:bg-gray-800 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <Link
                                to={item.href}
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-gray-800 dark:text-gray-300" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <Link
              to="/solutions"
              className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-300"
            >
              Solutions
            </Link>
            <Link
              to="/pricing"
              className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-300"
            >
              Pricing
            </Link>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900 dark:text-gray-300' : 'text-gray-500 dark:text-gray-200',
                      'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 dark:hover:text-gray-300'
                    )}
                  >
                    <span>Resources</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white dark:bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-pink-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-200">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900 dark:text-gray-300' : 'text-gray-500 dark:text-gray-200',
                      'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 dark:hover:text-gray-300'
                    )}
                  >
                    <span>Company</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white dark:bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8">
                          {company.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-pink-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-200">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link
              to="/demo"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-700 hover:bg-pink-600"
            >
              Request Demo
            </Link>
            <div className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 text-gray-400 hover:text-gray-500 dark:text-white dark:hover:text-gray-200">
              <ThemeToggler />
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-gray-900 divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://antsle.com/wp-content/uploads/2019/08/Antsle_logo_l.png"
                    alt="Antsle Logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white dark:bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {products.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-800"
                    >
                      <item.icon className="flex-shrink-0 h-6 w-6 text-pink-600" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-200">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {company.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-800"
                    >
                      <item.icon className="flex-shrink-0 h-6 w-6 text-pink-600" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-200">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              <Link
              to="/solutions"
              className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-300"
            >
              Solutions
            </Link>
            <Link
              to="/pricing"
              className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-300"
            >
              Pricing
            </Link>
                {resources.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700 dark:text-gray-200 dark:hover:text-gray-300"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div>
                <Link
                  to="/demo"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
                >
                  Request Demo
                </Link>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Have questions?{' '}
                  <Link to="/company/contact" className="text-pink-600 hover:text-pink-500">
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
