import React from 'react'
import { Link } from 'gatsby'
import { SiFacebook, SiTwitter, SiYoutube } from 'react-icons/si'

const navigation = {
    company: [
      { name: 'Request a Quote', href: '/request-a-quote' },
      { name: 'Become a Partner', href: '/partner' },
      { name: 'Subscribe to Email List', href: '/subscribe-to-mailing-list' },
      { name: 'Contact Us', href: '/company/contact' },
      { name: 'Press', href: '/company/press' },
      { name: 'White Papers', href: '/company/whitepapers' },
    ],
    support: [
      { name: 'Terms & Conditions', href: '/legal/terms-conditions' },
      { name: 'Privacy Policy', href: '/legal/privacypolicy' },
      { name: 'Warranty & Returns', href: '/legal/warranty' },
      { name: 'Shipping Rates & Policy', href: '/legal/shipping' },
      { name: 'Customer Support', href: 'https://support.antsle.com/' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://facebook.com/',
        icon: (props) => (
          <SiFacebook {... props} />
        ),
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/antsle_inc',
        icon: (props) => (
          <SiTwitter {... props} />
        ),
      },
      {
        name: 'Youtube',
        href: 'https://youtube.com/',
        icon: (props) => (
          <SiYoutube {... props} />
        ),
      },
    ],
  }
  
  export default function Footer() {
    return (
      <footer className="bg-white dark:bg-gray-900" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img
                className="h-10"
                src="https://antsle.com/wp-content/uploads/2019/08/Antsle_logo_l.png"
                alt="Antsle"
              />
              <p className="text-gray-500 dark:text-gray-300 text-base">
                Antsle - Simple Private Cloud.
              </p>
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500 dark:text-gray-300">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-base text-gray-500 dark:text-gray-300 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Support</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-base text-gray-500 dark:text-gray-300 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 dark:border-gray-700 pt-8">
            <p className="text-base text-gray-400 xl:text-center">&copy; {new Date().getFullYear()} Antsle, Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    )
  }
  