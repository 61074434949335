/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"
import React from "react"
import Layout from "./src/components/layout"
import Header from "./src/components/header"
import Footer from "./src/components/footer"

export function wrapPageElement({ element, props }) {
  return (
    <Layout {...props}>
      <Header />
      {element}
      <Footer />
    </Layout>
  )
}

export function wrapRootElement({ element }) {
  return <>{element}</>
}
